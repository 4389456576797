<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <!-- <th role="columnheader" class="text-center">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
               
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th> -->
            <th role="columnheader" class="text-center">
              <SelectCustomer
                :label="$t('labels.customer')"
                :placeholder="$t('labels.customer')"
                name="id_customer"
                sort-name="company_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :options="statusOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="status"
                sort-name="goods_receipt_status"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.group_tracking')"
                :placeholder="$t('labels.group_tracking')"
                name="goods_receipt_group_tracking"
                sort-name="goods_receipt_group_tracking"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.session_code')"
                :placeholder="$t('labels.session_code')"
                name="goods_receipt_tracking"
                sort-name="goods_receipt_tracking"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.tracking')"
                :placeholder="$t('labels.tracking')"
                name="tracking_id"
                sort-name="tracking_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <template v-if="['view1'].includes(viewOption)">
              <th role="columnheader" class="text-center">
                <DateRangeFilter
                  :label="$t('labels.created_at')"
                  :placeholder="$t('labels.created_at')"
                  name="create_time"
                  sort-name="create_time"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <DateRangeFilter
                  :label="$t('labels.receipt_time')"
                  :placeholder="$t('labels.receipt_time')"
                  name="handle_time"
                  sort-name="handle_time"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <DateRangeFilter
                  :label="$t('labels.stowing_time')"
                  :placeholder="$t('labels.stowing_time')"
                  name="stowing_time"
                  sort-name="stowing_time"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <InputFilter
                  :label="$t('labels.employee_create')"
                  :placeholder="$t('labels.employee_create')"
                  name="identity_create_name"
                  sort-name="identity_create_name"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <SelectEmployee
                  :label="$t('labels.employee_receipt')"
                  :placeholder="$t('labels.employee_receipt')"
                  name="id_employee_handle"
                  sort-name="employee_handle_name"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" class="text-center">
                <SelectEmployee
                  :label="$t('labels.employee_stowing')"
                  :placeholder="$t('labels.employee_stowing')"
                  name="id_employee_stowing"
                  sort-name="employee_stowing_name"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
            </template>
            <th>
              <SelectFilter
                :options="imageOptions"
                :label="$t('labels.image')"
                :placeholder="$t('labels.image')"
                name="image"
                sort-name="url_images"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.sku')"
                :placeholder="$t('labels.sku')"
                name="sku"
                sort-name="sku"
                :default-value="filters.sku"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.barcode')"
                :placeholder="$t('labels.barcode')"
                name="customer_goods_barcode"
                sort-name="customer_goods_barcode"
                :default-value="filters.customer_goods_barcode"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.position')"
                :placeholder="$t('labels.position')"
                name="cell_id"
                sort-name="cell_id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilterFromTo
                :label="$t('labels.request_quantity')"
                :placeholder="$t('labels.request_quantity')"
                name="request_quantity"
                sort-name="request_quantity"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilterFromTo
                :label="$t('labels.import_1')"
                :placeholder="$t('labels.import_1')"
                name="quantity"
                sort-name="quantity"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.transfer')"
                :placeholder="$t('labels.transfer')"
                name="is_transfer"
                sort-name="is_transfer"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <!-- <td>{{ item.warehouse_code }}</td> -->
            <td>{{ item.company_name }}</td>
            <td>{{ $t(`labels.receipt_status_${item.state}`) }}</td>
            <td>{{ item.goods_receipt_group_tracking }}</td>
            <td>{{ item.goods_receipt_tracking }}</td>
            <td>
              <span
                v-if="checkPermission(['goods_receipt_update'])"
                class="cursor-pointer blue--text text-decoration-underline"
                @click="showUpdateDialog(item)"
              >
                {{ item.tracking_id }}
              </span>
              <span v-else>{{ item.tracking_id }}</span>
            </td>
            <template v-if="['view1'].includes(viewOption)">
              <td>{{ formatDateTime(item.create_time) }}</td>
              <td>
                {{ item.handle_time ? formatDateTime(item.handle_time) : "" }}
              </td>
              <td>
                {{ item.stowing_time ? formatDateTime(item.stowing_time) : "" }}
              </td>
              <td>{{ item.identity_create_name }}</td>
              <td>{{ item.employee_handle_name }}</td>
              <td>{{ item.employee_stowing_name }}</td>
            </template>
            <td>
              <ImageViewer v-if="item.url_images" :url="item.url_images" />
            </td>
            <td>
              <SkuDownload :item="item" />
            </td>
            <td>{{ item.customer_goods_barcode }}</td>
            <td v-html="getCellByItem(item)"></td>
            <td>{{ item.request_quantity }}</td>
            <td>
              {{ item.quantity }}
              <v-icon
                v-if="
                  checkPermission(['super_admin']) &&
                  !item.no_label &&
                  !item.uid_printed_on_item &&
                  !item.has_stamp
                "
                small
                color="primary"
                @click="downloadStamp(item)"
                >mdi-download</v-icon
              >
            </td>
            <td>{{ item.is_transfer ? "Yes" : "No" }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="pt-3">
      <v-col cols="12" md="4">
        <div class="d-flex table-footer-count">
          <div class="mr-2">
            {{ $t("labels.tracking") }}:
            <b>{{ formatNumber(sum.sumTracking) }}</b>
          </div>
          <div class="mr-2">
            {{ $t("labels.sku") }}: <b>{{ formatNumber(sum.sumGoods) }}</b>
          </div>
        </div>
        <div class="d-flex table-footer-count mt-1">
          <!--<div class="mr-2">Y.cầu: <b>{{ formatNumber(sum.sumRequestQuantity) }}</b></div>-->
          <div class="mr-2">
            {{ $t("labels.receipt_1") }}:
            <b>{{ formatNumber(sum.sumQuantity) }}</b>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="text-right">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="12" md="4">
        <div class="d-flex">
          <a
            :href="`/goods/uid?sku=${filters.sku || ''}&barcode=${
              filters.customer_goods_barcode || ''
            }&`"
            target="_blank"
            v-if="checkPermission(['goods_uid_manager'])"
            class="mr-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary"
            style="height: 40px"
            >UID</a
          >
          <v-btn
            color="success"
            @click="exportExcel"
            v-if="checkPermission(['goods_receipt_excel'])"
            style="height: 40px"
            class="mr-2"
            ><v-icon>mdi-download</v-icon> {{ $t("labels.excel") }}</v-btn
          >
          <v-text-field
            class="c-input-xs mr-2"
            dense
            outlined
            hide-details
            v-model.number="page"
            :label="$t('labels.page')"
            :placeholder="$t('labels.page')"
          ></v-text-field>
          <v-btn
            color="secondary"
            style="height: 40px"
            @click="setViewOption('view0')"
            v-if="['view1'].includes(viewOption)"
            ><v-icon>mdi-arrow-collapse</v-icon>
            {{ $t("views.compact") }}</v-btn
          >
          <v-btn
            color="primary"
            style="height: 40px"
            @click="setViewOption('view1')"
            v-if="['view0'].includes(viewOption)"
            ><v-icon>mdi-arrow-expand</v-icon> {{ $t("views.full") }}</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="quantityDialog" persistent max-width="400px">
      <UpdateQuantity
        v-if="quantityDialog"
        :item="updatingItem"
        @cancel="hideUpdateDialog"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  debounce,
  formatDateTime,
  downloadPdfFile,
  downloadExcelFile,
  formatNumber,
  getUrlParameter,
} from "@/libs/helpers";
import { httpClient } from "@/libs/http";
import { IMAGE_OPTIONS, YES_NO_OPTIONS } from "@/libs/const";
import moment from "moment";

export default {
  components: {
    // SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectCustomer: () => import("@/components/table/SelectCustomer"),
    SelectEmployee: () => import("@/components/table/SelectEmployee"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    UpdateQuantity: () => import("@/components/goods_receipt/UpdateQuantity"),
    SkuDownload: () => import("@/components/goods/SkuDownload"),
    ImageViewer: () => import("@/components/goods/ImageViewer"),
  },
  name: "Index",
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    updatingItem: {},
    filters: {},
    sort: {},
    sum: {},
    viewOption: "view0",
    statusOptions: [],
    imageOptions: [...IMAGE_OPTIONS],
    yesNoOptions: [...YES_NO_OPTIONS],
    quantityDialog: false,
    isLoading: false,
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    const statusOptions = [
      {
        text: this.$t("labels.receipt_status_1"),
        value: 1,
      },
      {
        text: this.$t("labels.receipt_status_5"),
        value: 5,
      },
    ];
    this.statusOptions = [...statusOptions];
  },
  mounted() {
    const sku = this.getUrlParameter("sku");
    const customer_goods_barcode = this.getUrlParameter("barcode");
    if (sku) {
      this.filters = { ...this.filters, sku };
    }
    if (customer_goods_barcode) {
      this.filters = { ...this.filters, customer_goods_barcode };
    }

    this.getList();
  },
  methods: {
    formatDateTime,
    downloadPdfFile,
    downloadExcelFile,
    formatNumber,
    getUrlParameter,
    getList: debounce(function () {
      httpClient
        .post("/goods-receipt/v1/list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
          this.sum = { ...data.sum };
        });
    }, 500),
    setViewOption(mode) {
      this.viewOption = mode;
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      // this.sort = {...this.sort, [sort.name]: sort.value}
      // this.sort = {...sort}
      this.filters = { ...this.filters, ...sort };
    },
    getCellByItem(data) {
      if (data.items && data.items.length > 0) {
        const items = JSON.parse(data.items);
        const item = items.find((i) => i.id_goods_receipt_history == data.id);
        const goods_receipt_basket = (item && item.goods_receipt_basket) || [];
        const locations = goods_receipt_basket.map((i) => i.location) || [];
        return (
          '<div style="text-transform: uppercase">' +
          locations.join(",").split(",").join("<br>") +
          "</div>"
        );
      } else {
        return data.warehouse_location || null;
      }
    },
    async exportExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.waiting_file_download"));
        return false;
      }
      this.isLoading = true;
      const filename = "nhap-kho.xlsx";

      try {
        await this.downloadExcelFile(
          "/goods-receipt/v1/list-export",
          { ...this.filters, viewMode: this.viewOption },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
    async downloadStamp(item) {
      const stampSize = item.id_conformity_profile ? "50x40" : "50x20";
      const stampName = `${stampSize}_${item.sku}.pdf`;
      this.downloadExcelFile(
        `${process.env.VUE_APP_API_V2_URL}/common/v1/generate-goods-stamp`,
        {
          id_goods: item.id_goods,
          id_goods_receipt_history: item.id,
          client_time: moment().format("HH:mm DD/MM/YYYY"),
        },
        stampName
      );
    },
    showUpdateDialog(item) {
      this.quantityDialog = true;
      this.updatingItem = { ...item };
    },
    hideUpdateDialog() {
      this.quantityDialog = false;
      this.updatingItem = {};
    },
  },
};
</script>

<style scoped></style>
